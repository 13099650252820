import React, {useContext, useMemo, useState} from 'react'
import {Container} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import BaseTable from '../../Components/Tables/BaseTable'
import {
  cryptoIconsMapper,
  isTwoPrecision,
  prettyDate,
  toTwoPrecision,
} from "../../helpers/utils"
import FullTransactionModal from "../DashboardCrypto/FullTransactionModal"
import {ModalContext} from "../../contexts"
import TransactionBadgeIcon from "../../Components/Common/TransactionBadgeIcon"
import {useTranslation} from "react-i18next"

const Transactions = () => {
  const [selectedRows, setSelectedRows] = useState([])
  const {openModal} = useContext(ModalContext)
  const { t } = useTranslation()
  const selectedRowsHandler = (row) => {setSelectedRows(row)}

  const openTransactionHandler = (item) => {
    openModal({
      title: t('Transaction Details'),
      content: <FullTransactionModal selectedTransaction={item} />,
    })
  }

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='font-weight-bold fs-14'>ID</span>,
        selector: row => <a className="cursor-pointer" onClick={() => openTransactionHandler(row)}>#{row.id}</a>,
        sortable: true,
        width: '90px'
      },
      {
        id: "type",
        name: <span className="font-weight-bold fs-14">{t("Type")}</span>,
        width: "67px",
        selector: row =>
          <TransactionBadgeIcon
            transactionId={row.id}
            transactionType={row.type}
            invoiceId={row.invoice_id}
            invoiceAmount={row.usd_amount}
          />
      },
      {
        id: "date",
        name: <span className='font-weight-bold fs-14'>{t("Date")}</span>,
        selector: row => prettyDate(row.created_at),
        minWidth: '170px',
      },
      {
        id: "base_asset",
        name: <span className="font-weight-bold fs-14">{t("Base Asset")}</span>,
        selector: row => (
          <div className="d-flex align-items-center">
            <div className="avatar-xxs me-2">
              <img
                src={cryptoIconsMapper[row.base_asset.currency.code]}
                className="img-fluid"
                alt="3"
              />
            </div>
            <span>{row.base_asset.currency.code}</span>
          </div>
        ),
        minWidth: "155px",
      },
      {
        id: "quote_asset",
        name: <span className="font-weight-bold fs-14">{t("Quote Asset")}</span>,
        selector: row => (
          row.quote_asset && row.quote_asset.currency ?
            <div className="d-flex align-items-center">
              <div className="avatar-xxs me-2">
                <img
                  src={cryptoIconsMapper[row.quote_asset.currency.code]}
                  className="img-fluid"
                  alt="3"
                />
              </div>
              <span>{row.quote_asset.currency.code}</span>
            </div> :
            '-'
        ),
        minWidth: "100px",
      },
      {
        id: "amount",
        name: <span className='font-weight-bold fs-14'>{t("Amount")}</span>,
        selector: row => isTwoPrecision(row.base_asset.currency.code ,row.base_amount),
        minWidth: '150px',
      },
      {
        id: "usd_amount",
        name: <span className="font-weight-bold fs-14">{t("USD Amount")}</span>,
        selector: row => <span>${toTwoPrecision(row.usd_amount)}</span>,
        minWidth: '100px'
      },
      {
        id: "fee_pct",
        name: <span className="font-weight-bold fs-14">{t("Fee Pct")}</span>,
        selector: row => <span>{Number(row.fee_pct)}%</span>,
      },
      {
        id: "status",
        name: <span className="font-weight-bold fs-14">{t("Status")}</span>,
        selector: row => (
          <span className={'badge badge-soft-' + row.status_color}>
            {row.status === 'pending' ? (
              <i className="ri-time-line align-bottom me-1"></i>
            ) : (row.status === 'executed' ? (
              <i className="ri-checkbox-circle-line align-bottom me-1"></i>
            ) : (row.status === 'declined' ?
                <i className="ri-close-circle-line align-bottom me-1"></i> : ''
            ))}
            {t(`${row.status.capitalize()}`)}
          </span>
        ),
        minWidth: '105px',
      }
    ], [t])

  document.title = t("Transactions") + " | " + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Transactions"/>
          <BaseTable
            columns={columns}
            title={t("Transactions")}
            selectedRowsHandler={selectedRowsHandler}
            url="/spa/transactions/data"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Transactions;
