import {APIClient} from "../helpers/api_helper"

const api = new APIClient()

export const calculate = (data) => {
	return api.post('/spa/exchange/calculate', data)
}

export const calculateMaxSum = (data) => {
	return api.post('/spa/exchange/max-sum/calculate', data)
}

export const getExchangeFee = () => {
  return api.get('/spa/exchange/get-fee', {})
}

export const exchange = (data) => {
	return api.post('/spa/exchange', data)
}
